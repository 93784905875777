var Navigation = function($target){
    // set the element
    var self = this;
    self.$el = $target;

    var closeDelay = 0.7; // seconds

    //desktop
    self.$navDesktop = self.$el.find('nav.desktop');
    self.$primaryTabs = self.$navDesktop.find('li.primary');
    self.$secondaryTabs = self.$navDesktop.find('ul.primary li ul.secondary').children();

    var autoHide = function() {
        self.$primaryTabs.removeClass('hover');
        self.$secondaryTabs.removeClass('hover');
    }

    self.$primaryTabs.on('mouseenter click',function(){
        TweenMax.killDelayedCallsTo(autoHide);
        self.$primaryTabs.removeClass('hover');
        $(this).addClass('hover');
    });

    self.$primaryTabs.on('mouseleave',function(){
        TweenMax.delayedCall(closeDelay,autoHide)
    });

    self.$secondaryTabs.on('mouseenter click',function(){
        TweenMax.killDelayedCallsTo(autoHide);
        self.$secondaryTabs.removeClass('hover');
        $(this).addClass('hover');
    });

    self.$secondaryTabs.on('mouseleave',function(){
        TweenMax.delayedCall(closeDelay,autoHide)
    });

    // sticky
    /*var top = 60;
    $(window).scroll(function (event) {
        var y = $(this).scrollTop();
        if (y >= top)
            self.$navDesktop.addClass('sticky');
        else
            self.$navDesktop.removeClass('sticky');
    });*/

    // mobile
    self.isMenuOpen = false;
    self.$navToggle = self.$el.find('.navToggle');
    self.$navMobile = self.$el.find('nav.mobile');
    self.$mobileClose = self.$navMobile.find('.close');
    self.$mobileNavLinks = self.$navMobile.find('a');
    self.$mobileBg = self.$navMobile.find('.bg');
    self.$mobileNavHolder = self.$navMobile.find('.navHolder');
    self.$logoContainer = self.$el.find('.logoContainer');
    self.$primaryTabsMobile = self.$navMobile.find('li.primary');
    self.$secondaryTabsMobile = self.$navMobile.find('ul.primary li ul.secondary').children();

    self.$navToggle.on('click',function(e){
        e.preventDefault();
        e.stopPropagation();

        if (self.isMenuOpen) {
            self.closeNav();
        } else {
            self.openNav();
        }

    })

    self.$mobileClose.on('click',function(e){
        e.preventDefault();
        e.stopPropagation();
        self.closeNav();
    });


    // only close when the window is resized horizontally
    var lastWidth = window.innerWidth;
    $(window).on('resize',function(){
        if (lastWidth !== window.innerWidth){
            if (self.isMenuOpen) {
                self.closeNav();
            }
        }
        lastWidth = window.innerWidth;
    })


    $(window).on('scroll', function() {
        var scrollTop = $(document).scrollTop() + 143,
            HEIGHT = window.innerHeight;

        var percentage = .2 + ((((100 * scrollTop) / HEIGHT)/100)/1.2);

        if(scrollTop >= this.window.innerHeight && !$('nav.desktop').hasClass('shadow')) {
            $('nav.desktop').addClass('shadow');
        } else if($('nav.desktop').hasClass('shadow') && scrollTop <= this.window.innerHeight) {
            $('nav.desktop').removeClass('shadow');
        }

        $('.transparent nav.desktop').css('background', 'rgba(255, 255, 255, '+percentage+')');
        $('.transparent nav.desktop').css('background', 'rgba(255, 255, 255, '+percentage+')');
    }); 

    self.$primaryTabsMobile.on('click',function(){
        self.$primaryTabsMobile.removeClass('active');
        $(this).addClass('active');
    });

    self.$secondaryTabsMobile.on('click',function(){
        self.$secondaryTabsMobile.removeClass('active');
        $(this).addClass('active');
    });

    // navigate to url after animation
    self.$mobileNavLinks.on('click',function(e){
        e.preventDefault();
        e.stopPropagation();
        var url = $(this).attr('href');
        self.closeNav();
        TweenMax.delayedCall(0.7,function(){
            window.location.href = url;
        })
    });

 
};

Navigation.prototype.openNav = function(){
    var self = this;
    self.$navMobile.addClass('active');
    TweenMax.to(self.$mobileBg,0.5,{className:'+=active',ease:Strong.easeOut,overwrite:true,force3D:true})
    TweenMax.to(self.$mobileNavHolder,0.5,{className:'+=active',ease:Strong.easeOut,overwrite:false,force3D:true,delay:0.25})
    TweenMax.fromTo(self.$mobileNavHolder,0.5,{scale:1.1},{scale:1,ease:Strong.easeOut,overwrite:false,force3D:true,delay:0.25})
    TweenMax.to(self.$logoContainer,0.5,{y:-100+'%',ease:Strong.easeOut,overwrite:true,force3D:true})
    $('body').addClass('no-scroll');
    self.$navToggle.addClass('active');
    self.isMenuOpen = true;
}

Navigation.prototype.closeNav = function(){
    var self = this;
    self.isMenuOpen = false;
    TweenMax.to(self.$logoContainer,0.4,{y:0+'%',ease:Strong.easeOut,overwrite:true,force3D:true})
    TweenMax.to(self.$mobileNavHolder,0.4,{className:'-=active',ease:Strong.easeOut,overwrite:false,force3D:true})
    TweenMax.to(self.$mobileNavHolder,0.4,{scale:1.1,ease:Strong.easeOut,overwrite:false,force3D:true})
    TweenMax.to(self.$mobileBg,0.4,{className:'-=active',ease:Strong.easeOut,overwrite:true,force3D:true,delay:0.15,onComplete:function(){
        self.$navToggle.removeClass('active');
        $('body').removeClass('no-scroll');
        self.$navMobile.removeClass('active');
        self.$secondaryTabsMobile.removeClass('active');
        self.$primaryTabsMobile.removeClass('active');
    }})
}

module.exports = Navigation;
