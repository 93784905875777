var FindApartment = function($target){
    // set the element
    var self = this;
    self.$el = $target;
    self.$dataUrl = self.$el.attr('data-src');
    self.$data = [];
    self.$currentBuilding = [];
    self.$currentApartment = [];
    self.$hotspots = self.$el.find('.hotspot');
    self.$buildingNumber = self.$el.find('.buildingNumber');
    self.$buildingDesc = self.$el.find('.buildingDesc');
    self.$buildingFeatures = self.$el.find('.buildingFeatures');
    self.$aptListContainer = self.$el.find('ul.apartmentList');
    self.$apartmentMap = self.$el.find('.apartmentMap');
    self.$apartmentViewSpots = self.$apartmentMap.find('.viewSpots');
    self.$buildings = self.$el.find('.build');
    self.$apartmentNumber = self.$el.find('.apartmentNumber');
    self.$apartmentFloor = self.$el.find('.aptFloorData');
    self.$apartmentBed = self.$el.find('.aptBedroomsData');
    self.$apartmentStatus = self.$el.find('.aptStatusData');
    self.$apartmentInfoList = self.$el.find('ul.apartmentInfoList');
    self.$sqMTotal = self.$el.find('.sqM-total');
    self.$sqFTotal = self.$el.find('.sqF-total');
    self.$screensHolder = self.$el.find('.screensHolder');
    self.$screen1 = self.$screensHolder.find('.screen-1');
    self.$screen2 = self.$screensHolder.find('.screen-2');
    self.$screen3 = self.$screensHolder.find('.screen-3');
    self.$mapButton = self.$el.find('a.mp');
    self.$backButton = self.$el.find('a.bck');
    self.$spotViewer = $('.spotViewer');
    self.$spotViewerBg = self.$spotViewer.find('.bg');
    self.$spotViewerImage = self.$spotViewer.find('.imgHolder');
    self.$spotImage = self.$spotViewer.find('img');
    self.$spotViewerClose = self.$spotViewer.find('.close');
    self.animationTime = 0.6;
    self.isFirstScreen = true;


    // load data
    $.getJSON(self.$dataUrl, function(data) {
        self.$data = data.buildings;
    })
    .done(function() {
        // console.log(self.$data)

        // hotspots clickthroughs
        self.$hotspots.on('click', function(e){
            e.preventDefault();
            e.stopPropagation();
            var el = $(this);
            var option = el.attr('data-option');
            self.$hotspots.removeClass('selected');
            el.addClass('selected');

            // highlight selected building
            self.$buildings.removeClass('selected');
            self.$buildings.filter('.b_'+option).addClass('selected');

            // goto screen 2
            if (self.isFirstScreen) {
                TweenMax.to(self.$screen1,self.animationTime,{className:'-=active',ease:Strong.easeIn,onComplete:function(){
                    self.setCurrentBuilding(option)
                    TweenMax.to(self.$screensHolder,self.animationTime,{height:self.$screen2.outerHeight(),ease:Strong.easeOut});
                    TweenMax.to(self.$screen2,self.animationTime,{className:'+=active',ease:Strong.easeOut});
                    self.isFirstScreen = false;
                }})
            } else {
                TweenMax.to(self.$screen2,self.animationTime,{className:'-=active',ease:Strong.easeIn,onComplete:function(){
                    self.setCurrentBuilding(option)
                    TweenMax.to(self.$screensHolder,self.animationTime,{height:self.$screen2.outerHeight(),ease:Strong.easeOut});
                    TweenMax.to(self.$screen2,self.animationTime,{className:'+=active',ease:Strong.easeOut});
                }})
            }

        })

        // back button
        self.$backButton.on('click',function(e){
            e.preventDefault();
            e.stopPropagation();
            // hide back button
            self.$mapButton.css('display','block');
            self.$backButton.css('display','none');
            // go back to building screen
            TweenMax.to(self.$screen3,self.animationTime,{className:'-=active',ease:Strong.easeIn,onComplete:function(){
                TweenMax.to(self.$screensHolder,self.animationTime,{height:self.$screen2.outerHeight(),ease:Strong.easeOut});
                TweenMax.to(self.$screen2,self.animationTime,{className:'+=active',ease:Strong.easeOut});
            }})
            // hide apartmentMap
            TweenMax.to(self.$apartmentMap,self.animationTime,{className:'-=active',ease:Strong.easeIn});
        })
    })
    .fail(function() {
        console.log('failed to load data')
    })


    // resize screen holders container
    self.$screensHolder.css('height', self.$el.find('.screen.active').outerHeight());
    $(window).on('resize',function(){
        self.$screensHolder.css('height', self.$el.find('.screen.active').outerHeight());
    })


    // close spot viewer
    self.$spotViewerClose.on('click',function(e){
        e.preventDefault();
        e.stopPropagation();
        self.closeViewSpot();
    })

    $(document).click(function(event) {
        if(!$(event.target).closest(self.$spotImage).length &&
           !$(event.target).is(self.$spotImage)) {
            self.closeViewSpot();
        }
    })
}

FindApartment.prototype.setCurrentBuilding = function(building) {
    var self = this;

    self.$currentBuilding = self.$data.filter(function(b){
        return b.number == building
    })
    self.$currentBuilding = self.$currentBuilding[0];

    // populate building info
    self.$buildingNumber.html(self.$currentBuilding.number)
    self.$buildingDesc.html(self.$currentBuilding.desc)
    self.$buildingFeatures.html('');
    for (var i=0; i<self.$currentBuilding.features.length; i++){
        var feature = self.$currentBuilding.features[i];
        self.$buildingFeatures.append("<div class='feature' style='background-image:url("+feature.image+")'>"+feature.name+"</div>")
    }

    self.$aptListContainer.html('<li><div class="cell">NO.</div><div class="cell">FLOOR</div><div class="cell">BEDROOMS</div><div class="cell">STATUS</div></li>');
    for (var k=0; k<self.$currentBuilding.apartments.length; k++){
        var apartment = self.$currentBuilding.apartments[k];
        self.$aptListContainer.append("<li data-aptno="+apartment.number+"><div class='cell apt-no'>"+apartment.number+"</div><div class='cell apt-type'>"+apartment.type+"</div><div class='cell apt-beds'>"+apartment.bedrooms+"</div><div class='cell apt-status'>"+apartment.status+"</div></li>");
    }

    self.$aptListContainer.find('li').on('click',function(e){
        e.preventDefault();
        e.stopPropagation();
        var el = $(this);
        var option = el.attr('data-aptno');
        self.setCurrentApartment(option)

        // goto screen 3
        TweenMax.to(self.$screen2,self.animationTime,{className:'-=active',ease:Strong.easeIn,onComplete:function(){
            self.setCurrentApartment(option)
            TweenMax.to(self.$screensHolder,self.animationTime,{height:self.$screen3.outerHeight(),ease:Strong.easeOut});
            TweenMax.to(self.$screen3,self.animationTime,{className:'+=active',ease:Strong.easeOut});
        }})

        // bring apartmentMap
        TweenMax.to(self.$apartmentMap,self.animationTime,{className:'+=active',ease:Strong.easeOut,delay:self.animationTime});

        // show back button
        self.$mapButton.css('display','none');
        self.$backButton.css('display','block');
    })
}

FindApartment.prototype.setCurrentApartment = function(apartment) {
    var self = this;

    self.$currentApartment = self.$currentBuilding.apartments.filter(function(b){
        return b.number == apartment
    })
    self.$currentApartment = self.$currentApartment[0];

    // populate apartment info
    self.$apartmentNumber.html(self.$currentApartment.number);
    self.$apartmentFloor.html(self.$currentApartment.type);
    self.$apartmentBed.html(self.$currentApartment.bedrooms);
    self.$apartmentStatus.html(self.$currentApartment.status);
    self.$apartmentInfoList.html('<li><div class="cell">ROOM</div><div class="cell">SQ M</div><div class="cell">SQ F</div></li>');
    var totalSqM = 0;
    var totalSqF = 0;
    for (var i=0; i<self.$currentApartment.rooms.length; i++){
        var room = self.$currentApartment.rooms[i];
        self.$apartmentInfoList.append("<li><div class='cell'>"+room.name+"</div><div class='cell'>"+room.roomSqM+"</div><div class='cell '>"+room.roomSqF+"</div></li>");
        totalSqM += parseInt(room.roomSqM)
        totalSqF += parseInt(room.roomSqF)
    }
    //self.$apartmentInfoList.append('<li><div class="cell">TOTAL</div><div class="cell">'+totalSqM.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+'</div><div class="cell">'+totalSqF.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+'</div></li>');

    // set image and hotspots
    self.$apartmentMap.css('background-image', 'url("'+self.$currentApartment.image+'")');
    self.$apartmentViewSpots.html('');
    for (var k=0; k<self.$currentApartment.viewSpots.length; k++){
        var viewSpot = self.$currentApartment.viewSpots[k];
        self.$apartmentViewSpots.append("<div class='viewSpot' style='top:"+viewSpot.spotTop+"%;left:"+viewSpot.spotLeft+"%;' data-img='"+viewSpot.image+"'></div>")
    }

    self.$apartmentViewSpots.find('.viewSpot').on('click',function(e){
        e.preventDefault();
        e.stopPropagation();
        var el = $(this);
        var img = el.attr('data-img');
        self.viewSpot(img);
    })
}

FindApartment.prototype.viewSpot = function(image) {
    var self = this;
    // set image
    self.$spotImage.attr('src',image);
    // open overlay, view image
    self.$spotViewer.addClass('active');
    TweenMax.to(self.$spotViewerBg,self.animationTime,{alpha:1,ease:Strong.easeOut})
    TweenMax.to(self.$spotViewerImage,self.animationTime,{alpha:1,ease:Strong.easeOut,delay:self.animationTime})
    $('body').addClass('no-scroll');
    // bring close button
    TweenMax.to(self.$spotViewerClose,self.animationTime,{className:'+=active',ease:Strong.easeOut,delay:self.animationTime});
}

FindApartment.prototype.closeViewSpot = function() {
    var self = this;
    // hide close button
    TweenMax.to(self.$spotViewerClose,self.animationTime,{className:'-=active',ease:Strong.easeIn});
    // close image overlay
    TweenMax.to(self.$spotViewerImage,self.animationTime/1.5,{alpha:0,ease:Strong.easeOut})
    TweenMax.to(self.$spotViewerBg,self.animationTime/1.5,{alpha:0,ease:Strong.easeOut,delay:self.animationTime/1.5,onComplete:function(){
        self.$spotViewer.removeClass('active');
        $('body').removeClass('no-scroll');
    }})
}


module.exports = FindApartment;
