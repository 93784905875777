var VideoPlayer = function ($target) {

    //set props
    this.element = $target;
    this.videoContainer = $target.find('.videoPlayerVideo');
    this.video = $target.find('video');
    this.menu = $target.find('.videoPlayerMenu ul');
    this.menuItems = $target.find('.videoPlayerMenu li');
    this.activeVideo = 0;
    this.navArrows = $target.find('.videoPlayerNav a');
    this.menuOffset = 0;
    this.navLegend = $target.find('.videoPlayerNavLegend');
    this.scrollTimestamp = new Date().getTime();
    this.orientation = function () {
        return (window.innerWidth >= 768 ) ? "vertical" : "horizontal";
    }
    this.isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    //initialise immediately on instancing
    this.init();
}

VideoPlayer.prototype.init = function () {

   //init code


    this.player = this.video;
    
    this.navLegend.find('.total').text(this.menuItems.length);

    this.resizeElements();

    this.registerEvents();

}

VideoPlayer.prototype.resizeElements = function () {
    if(this.orientation() == "horizontal") {
        this.menuItems.css('width', parseInt(this.videoContainer.width()/2) + 1);
        
    } else {
        this.menuItems.css('width', '100%');
        
    }

    if(this.menuOffset!=0) {
        this.menuOffset = 0;
        TweenMax.to(this.menu[0], 0, {x : 0, y : 0});
    }
}



//register events
VideoPlayer.prototype.registerEvents = function () {
    var self = this;

    this.menuItems.on('click', function(){
        self.menuItems.removeClass('playing');
        self.menuItems.addClass('paused');
        $(this).removeClass('paused');
        $(this).addClass('playing');
        var i = $(this).index();
        self.activeVideo = i;
        self.updateSource($(this).data('src'));


        self.player[0].play();

        self.navLegend.find('.current').text(i+1);
    });


    this.menu.on('mousewheel DOMMouseScroll', function(e){
        e.preventDefault();
        var delta = e.originalEvent.wheelDelta || e.originalEvent.detail;
        var dir = delta > 0 ? 1 : -1;
        
        var now = new Date().getTime();
        
        if(now - self.scrollTimestamp > 300) {
            self.moveMenu(dir);  
            self.scrollTimestamp = now;
        }
        

    });


    this.navArrows.on('click', function(e){
        e.preventDefault();
        
        var dir = $(this).hasClass('arrowUp') ? 1 : -1;

        self.moveMenu(dir);   

    });


    this.menu.swipe({
        swipeLeft : function () {
            self.moveMenu(-1);
        },
        swipeRight : function () {
            self.moveMenu(1);
        },
        swipeUp: function () {
            self.moveMenu(-1);
        },
        swipeDown : function () {
            self.moveMenu(1);
        },
        allowPageScroll:"none"
    })


    $(window).on('resize', function(e){

        if(!self.element.hasClass(self.orientation())) {
            self.element.removeClass('horizontal vertical');
            self.element.addClass(self.orientation());
        }

        self.resizeElements();

    });
}

VideoPlayer.prototype.moveMenu = function (dir){ 
    
    var distance, i;

    if(this.orientation() == "vertical") {
        distance = 150;
        i = (Math.abs(this.menuOffset) / distance) + 1;
        this.moveMenuY(dir, i, distance);
    } else if(this.orientation() == "horizontal"){
        distance = this.videoContainer.width()/2;
        i = (Math.abs(this.menuOffset) / distance) + 1;
        this.moveMenuX(dir, i, distance)
    }
    

}

VideoPlayer.prototype.moveMenuX = function(dir, i, distance) {

    var self = this;
    
    if(i == 1 && dir == 1) {
        return false;
    }

    if((self.menuItems.length - i) < 2 && dir == -1) {
        return false;
    }

    TweenMax.to(self.menu[0], .5, {
        x : (self.menuOffset + (distance * dir)) + 'px'
    });

    self.menuOffset = (self.menuOffset + (distance * dir))

}

VideoPlayer.prototype.moveMenuY = function (dir, i, distance) {
    var self = this;
    
    if(i == 1 && dir == 1) {
        return false;
    }

    if((self.menuItems.length - i) < 3 && dir == -1) {
        return false;
    }

    TweenMax.to(self.menu[0], .5, {
        y : (self.menuOffset + (distance * dir)) + 'px'
    });

    self.menuOffset = (self.menuOffset + (distance * dir))

}


VideoPlayer.prototype.updateSource = function(src) {

    var self = this;

    this.video.attr('src', src);

}



module.exports = VideoPlayer;