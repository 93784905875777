var FindVilla = function($target){
    // set the element
    var self = this;
    self.$el = $target;
    self.$dataUrl = self.$el.attr('data-src');
    self.$data = [];
    self.$section = "";
    self.$currentVilla = [];
    self.$currentApartment = [];
    self.$hotspotsHolder = self.$el.find('.hotspots');
    self.$hotspots = self.$el.find('.hotspot');
    self.$buildingFeatures = self.$el.find('.buildingFeatures');
    self.$aptListContainer = self.$el.find('ul.apartmentList');
    self.$apartmentMap = self.$el.find('.apartmentMap');
    self.$apartmentTabs = self.$el.find('.apartmentMap .tabs');
    self.$apartmentPages = self.$el.find('.apartmentMap .pages');
    self.$apartmentViewSpots = self.$apartmentMap.find('.viewSpots');
    self.$apartmentNumber = self.$el.find('.apartmentNumber');
    self.$apartmentFloor = self.$el.find('.aptFloorData');
    self.$apartmentBed = self.$el.find('.aptBedroomsData');
    self.$apartmentStatus = self.$el.find('.aptStatusData');
    self.$apartmentInfoList = self.$el.find('ul.apartmentInfoList');
    self.$sqMTotal = self.$el.find('.sqM-total');
    self.$sqFTotal = self.$el.find('.sqF-total');
    self.$screensHolder = self.$el.find('.screensHolder');
    self.$screen1 = self.$screensHolder.find('.screen-1');
    self.$screen2 = self.$screensHolder.find('.screen-2');
    self.$mapButton = self.$el.find('a.mp');
    self.$backButton = self.$el.find('a.bck');
    self.$spotViewer = $('.spotViewer');
    self.$spotViewerBg = self.$spotViewer.find('.bg');
    self.$spotViewerImage = self.$spotViewer.find('.imgHolder');
    self.$spotImage = self.$spotViewer.find('img');
    self.$spotViewerClose = self.$spotViewer.find('.close');
    self.animationTime = 0.6;

    // load data
    $.getJSON(self.$dataUrl, function(data) {
        self.$data = data.villas;
        self.$section = data.section;
    })
    .done(function() {
        // console.log(self.$data)

        // build hotspots
        //console.log("sec: ",self.$section);

        //console.log("url: ",self.$data);
        if(self.$section.indexOf("Villas") >= 0){
            self.$aptListContainer.html('<li><div class="cell">NO.</div><div class="cell">BEDROOMS</div><div class="cell">STATUS</div></li>');
        }else{
            self.$aptListContainer.html('<li><div class="cell">NO.</div><div class="cell">ARCHITECTURE</div><div class="cell">STATUS</div></li>');
        }
        for (var i=0; i<self.$data.length; i++){
            var villa = self.$data[i];
            self.$hotspotsHolder.append('<div class="hotspot" data-option="'+villa.number+'" style="top:'+villa.spotTop+'%; left:'+villa.spotLeft+'%;"><span>'+villa.number+'</span></div>');
            
        if(self.$section.indexOf("Villas") >= 0){
                self.$aptListContainer.append("<li data-aptno="+villa.number+"><div class='cell apt-no'>"+villa.number+"</div><div class='cell apt-beds'>"+villa.bedrooms+"</div><div class='cell apt-status'>"+villa.status+"</div></li>");
            }else{
                self.$aptListContainer.append("<li data-aptno="+villa.number+"><div class='cell apt-no'>"+villa.number+"</div><div class='cell apt-beds'>"+villa.architecture+"</div><div class='cell apt-status'>"+villa.status+"</div></li>");
            }
        }
        self.$hotspots = self.$el.find('.hotspot');

        // hotspots, list clickthroughs
        self.$hotspots.on('click', function(e){
            e.preventDefault();
            e.stopPropagation();
            var el = $(this);
            var option = el.attr('data-option');
            self.goToVilla(option);
        })
        self.$aptListContainer.find('li').on('click',function(e){
            e.preventDefault();
            e.stopPropagation();
            var el = $(this);
            var option = el.attr('data-aptno');
            self.goToVilla(option);
        })

        // back button
        self.$backButton.on('click',function(e){
            e.preventDefault();
            e.stopPropagation();
            // hide back button
            self.$mapButton.css('display','block');
            self.$backButton.css('display','none');
            // go back to building screen
            TweenMax.to(self.$screen2,self.animationTime,{className:'-=active',ease:Strong.easeIn,onComplete:function(){
                TweenMax.to(self.$screensHolder,self.animationTime,{height:self.$screen1.outerHeight(),ease:Strong.easeOut});
                TweenMax.to(self.$screen1,self.animationTime,{className:'+=active',ease:Strong.easeOut});
            }})
            // hide apartmentMap
            TweenMax.to(self.$apartmentMap,self.animationTime,{className:'-=active',ease:Strong.easeIn});
        })
    })
    .fail(function() {
        console.log('failed to load data')
    })


    // resize screen holders container
    self.$screensHolder.css('height', self.$el.find('.screen.active').outerHeight());
    $(window).on('resize',function(){
        self.$screensHolder.css('height', self.$el.find('.screen.active').outerHeight());
    })


    // close spot viewer
    self.$spotViewerClose.on('click',function(e){
        e.preventDefault();
        e.stopPropagation();
        self.closeViewSpot();
    })

    $(document).click(function(event) {
        if(!$(event.target).closest(self.$spotImage).length &&
           !$(event.target).is(self.$spotImage)) {
            self.closeViewSpot();
        }
    })
}

FindVilla.prototype.goToVilla = function(villa){
    var self = this;

    TweenMax.to(self.$screen1,self.animationTime,{className:'-=active',ease:Strong.easeIn,onComplete:function(){
        self.setCurrentVilla(villa)
        TweenMax.to(self.$screensHolder,self.animationTime,{height:self.$screen2.outerHeight(),ease:Strong.easeOut});
        TweenMax.to(self.$screen2,self.animationTime,{className:'+=active',ease:Strong.easeOut});
    }})

    // bring apartmentMap
    TweenMax.to(self.$apartmentMap,self.animationTime,{className:'+=active',ease:Strong.easeOut,delay:self.animationTime});

    // show back button
    self.$mapButton.css('display','none');
    self.$backButton.css('display','block');
}

FindVilla.prototype.setCurrentVilla = function(villa) {
    var self = this;

    self.$currentVilla = self.$data.filter(function(b){
        return b.number == villa
    })
    self.$currentVilla = self.$currentVilla[0];

    // populate villa features
    self.$buildingFeatures.html('');
    for (var i=0; i<self.$currentVilla.features.length; i++){
        var feature = self.$currentVilla.features[i];
        self.$buildingFeatures.append("<div class='feature' style='background-image:url("+feature.image+")'>"+feature.name+"</div>")
    }

    // populate villa info
    self.$apartmentNumber.html(self.$currentVilla.number);
    if(self.$section.indexOf("Villas") >= 0) {
        self.$apartmentBed.html(self.$currentVilla.architecture);
    }else{
        self.$apartmentBed.html(self.$currentVilla.bedrooms); 
    }   
    self.$apartmentStatus.html(self.$currentVilla.status);
    self.$apartmentInfoList.html('<li><div class="cell">ROOM</div><div class="cell">SQ M</div><div class="cell">SQ F</div></li>');
    var totalSqM = 0;
    var totalSqF = 0;
    for (var i=0; i<self.$currentVilla.rooms.length; i++){
        var room = self.$currentVilla.rooms[i];
        self.$apartmentInfoList.append("<li><div class='cell'>"+room.name+"</div><div class='cell'>"+room.roomSqM+"</div><div class='cell '>"+room.roomSqF+"</div></li>");
        totalSqM += parseInt(room.roomSqM)
        totalSqF += parseInt(room.roomSqF)
    }
    //self.$apartmentInfoList.append('<li><div class="cell">TOTAL</div><div class="cell">'+totalSqM.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+'</div><div class="cell">'+totalSqF.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+'</div></li>');

    // set villa floor map images, hotspots
    self.$apartmentTabs.html('');
    self.$apartmentPages.html('');
    for (var i=0; i<self.$currentVilla.floors.length; i++){
        var floor = self.$currentVilla.floors[i];

        self.$apartmentTabs.append('<div class="tab" data-page="floor_'+i+'">'+floor.title+'</div>');

        var page = $('<div class="page" data-page="floor_'+i+'" style="background-image:url('+floor.image+');"></div>');
        var floorViewSpots = $('<div class="viewSpots"></div>');

        for(var k=0;k<floor.viewSpots.length;k++){
            var viewSpot = floor.viewSpots[k];
            floorViewSpots.append("<div class='viewSpot' style='top:"+viewSpot.spotTop+"%;left:"+viewSpot.spotLeft+"%;' data-img='"+viewSpot.image+"'></div>")
        }

        page.append(floorViewSpots);
        self.$apartmentPages.append(page);
    }

    // set first items as active
    self.$apartmentTabs.children().first().addClass('active');
    self.$apartmentPages.children().first().addClass('active');
    // floor tabs
    self.$apartmentTabs.find('.tab').on('click',function(e){
        e.preventDefault();
        e.stopPropagation();
        var el = $(this);
        var page = el.attr('data-page');
        // active tab
        self.$apartmentTabs.find('.tab').removeClass('active');
        el.addClass('active');
        // active page
        var currentPage = self.$apartmentPages.find('.active');
        var selectedPage = self.$apartmentPages.find("[data-page='"+page+"']");
        TweenMax.to(currentPage,self.animationTime,{className:'-=active',ease:Strong.easeIn,onComplete:function(){
            TweenMax.to(selectedPage,self.animationTime,{className:'+=active',ease:Strong.easeOut});
        }})
    })
    // viewspots clicks
    self.$apartmentMap.find('.viewSpot').on('click',function(e){
        e.preventDefault();
        e.stopPropagation();
        var el = $(this);
        var img = el.attr('data-img');
        self.viewSpot(img);
    })
}

FindVilla.prototype.viewSpot = function(image) {
    var self = this;
    // set image
    self.$spotImage.attr('src',image);
    // open overlay, view image
    self.$spotViewer.addClass('active');
    TweenMax.to(self.$spotViewerBg,self.animationTime,{alpha:1,ease:Strong.easeOut})
    TweenMax.to(self.$spotViewerImage,self.animationTime,{alpha:1,ease:Strong.easeOut,delay:self.animationTime})
    $('body').addClass('no-scroll');
    // bring close button
    TweenMax.to(self.$spotViewerClose,self.animationTime,{className:'+=active',ease:Strong.easeOut,delay:self.animationTime});
}

FindVilla.prototype.closeViewSpot = function() {
    var self = this;
    // hide close button
    TweenMax.to(self.$spotViewerClose,self.animationTime,{className:'-=active',ease:Strong.easeIn});
    // close image overlay
    TweenMax.to(self.$spotViewerImage,self.animationTime/1.5,{alpha:0,ease:Strong.easeOut})
    TweenMax.to(self.$spotViewerBg,self.animationTime/1.5,{alpha:0,ease:Strong.easeOut,delay:self.animationTime/1.5,onComplete:function(){
        self.$spotViewer.removeClass('active');
        $('body').removeClass('no-scroll');
    }})
}


module.exports = FindVilla;
