var CourseMap = function($target){
    // set the element
    var self = this;
    self.$el = $target;

    self.$hotspots = self.$el.find('.hotspot');
    self.$maskContainer = self.$el.find('.maskContainer');

    self.$spotViewer = $('.spotViewer');
    self.$spotViewerBg = self.$spotViewer.find('.bg');
    self.$spotViewerImage = self.$spotViewer.find('.imgHolder');

    self.$spotImage = self.$spotViewer.find('img');
    self.$spotViewerClose = self.$spotViewer.find('.close');
    self.animationTime = 0.3;
    self.isFirstScreen = true;


    self.$activeDescription = false;

    // close spot viewer
    self.$spotViewerClose.on('click',function(e){
        e.preventDefault();
        e.stopPropagation();
        self.closeViewSpot();
    });




    self.currentSpot = [];

    self.$hotspots.on('click',function(e){
        e.preventDefault();
        e.stopPropagation();
        var el = $(this);
        var selectedOption = el.attr('data-option');
        self.$hotspots.removeClass('selected');
        self.$el.find("[data-option='" + selectedOption + "']").addClass('selected');
        self.$maskContainer.attr('data-selected',selectedOption);
        self.currentSpot = self.$el.find('.menu').find('.selected');
        
        self.viewSpot(selectedOption);

       /*  self.currentSpot.on('click',function(){
            //var img = $(this).attr('data-image');
            self.viewSpot(selectedOption);
        }) */
    })
	if (document.querySelector('.resortMap')){
		self.$hotspots.on({
			mouseenter: function(e) {
				 e.preventDefault();
				e.stopPropagation();
				var el = $(this);
				var selectedOption = el.attr('data-option');
				self.$hotspots.removeClass('selected');
				self.$el.find("[data-option='" + selectedOption + "']").addClass('selected');
			},		
			mouseleave: function(e){
				 e.preventDefault();
				e.stopPropagation();
				self.$hotspots.removeClass('selected');
			}

		});
	}
	
	
	
    self.$el.find('.selectTitle').on('click',function(e){
        e.preventDefault();
        e.stopPropagation();
        self.$hotspots.removeClass('selected');
        self.$maskContainer.attr('data-selected','');
    })

    self.$el.find('.imgHolderInner').on('click', function(e){
        e.preventDefault();
        
        $(this).toggleClass('flipped')

    });



    // close spot viewer if click outside image
    /* $(document).click(function(event) {
        if(!$(event.target).closest($self.ativ).length &&
           !$(event.target).is(self.$holeDescription)) {
            self.closeViewSpot();
        }
    }) */

/*     $(document).on('click', function(e){

        if(!$(e.target).is(self.$activeDescription.find('.holeDescription')) &&
        !$(e.target).closest(self.$activeDescription).length) {
            alert();
        }

    }); */
}


CourseMap.prototype.viewSpot = function(index) {
    var self = this;

    self.$activeDescription =  self.$spotViewer.eq(parseInt(index)-1);
    self.$activeDescription.addClass('courseMap-viewer');

    self.$el.addClass('lightbox');



    $(self.$activeDescription).on('scroll', function(){
  
        console.log('there`s an active description');
        if(!self.$activeDescription.find('.scrollTip').hasClass('hidden')) {
            console.log('still visible');
            self.$activeDescription.find('.scrollTip').addClass('hidden');
        }

        
    });

    // set image
    //self.$el.addClass('lightbox');
    //self.$spotImage.attr('src',image);
    // open overlay, view image
    self.$activeDescription.addClass('active');
    TweenMax.to(self.$spotViewerBg,self.animationTime,{alpha:1,ease:Strong.easeOut})
    TweenMax.to(self.$spotViewerImage,self.animationTime,{alpha:1,ease:Strong.easeOut,delay:self.animationTime})
    $('body').addClass('no-scroll');
    // bring close button
    TweenMax.to(self.$spotViewerClose,self.animationTime,{className:'+=active',ease:Strong.easeOut,delay:self.animationTime});
}

CourseMap.prototype.closeViewSpot = function() {
    var self = this;


    self.$activeDescription.find('.scrollTip').removeClass('hidden');

    //self.$el.removeClass('lightbox');
    self.$activeDescription = false;
    self.$el.removeClass('lightbox');
    // hide close button
    TweenMax.to(self.$spotViewerClose,self.animationTime,{className:'-=active',ease:Strong.easeIn});
    // close image overlay
    TweenMax.to(self.$spotViewerImage,self.animationTime/1.5,{alpha:0,ease:Strong.easeOut})
    TweenMax.to(self.$spotViewerBg,self.animationTime/1.5,{alpha:0,ease:Strong.easeOut,delay:self.animationTime/1.5,onComplete:function(){
        self.$spotViewer.removeClass('active courseMap-viewer');
        $('body').removeClass('no-scroll');
    }})
}


module.exports = CourseMap;
