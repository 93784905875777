var CookiePolicy = function () {


    //Cookie name to be saved in user's machine
    this.cookieName = 'monte-rei-accept-cookies';
    //Privacy policy page url
    this.privacyPolicyUrl = '/en/privacy-policy/'

    // Template
    this.template = '<div class="cookie-popup"><p>This website uses cookies to ensure the best possible experience for our users. <a href="' + this.privacyPolicyUrl + '" target="_blank">Click here</a> to read more about our Privacy Policy. <button class="cookie-popup-accept">Accept </button></p></div>';

  
    //initialise immediately on instancing
    this.init();
}

CookiePolicy.prototype.init = function () {

        //console.log(window.localStorage.getItem("monte-rei-has-cookies") == "true");
    //If user hasn't accepted, append cookie message and register DOM events
    if(!this.hasAccepted() && window.localStorage.getItem("monte-rei-accept-cookies") != "true") {

    //if(!this.hasAccepted()) {
        $('body').append(this.template);
        window.localStorage.setItem("monte-rei-accept-cookies", 'false');
        this.registerEvents();
    }
   
}


//Check if user has or hasn't accepted the cookie popup before so we don't show it again
CookiePolicy.prototype.hasAccepted = function () {

    var hasAccepted = false;

    //If it was save in localstorage in the past, then returns true
    if(window.localStorage.getItem(this.cookieName) == "true") {
        hasAccepted = true;
    }

    return hasAccepted;

}


//Register click event on recently added DOM element button
CookiePolicy.prototype.registerEvents = function () {
    var self = this;

    $('.cookie-popup-accept').on('click', function() {

        //Accept the cookies
       self.acceptCookies();

    });
}

//User has accepted so set cookie on local storage to string 'true' and remove the
// cookie popup message
CookiePolicy.prototype.acceptCookies = function () {

    window.localStorage.setItem(this.cookieName, 'true');
    
    $('.cookie-popup').remove();

}



module.exports = CookiePolicy;