'use strict';
window.polyfill = require('./polyfill'); // ie11 fixes
window.$ = require('jquery');
window.jQuery = $;
window.Flickity = require('flickity');
window.gsap = require('gsap');
window.iFrameResizer = require('iframe-resizer');
window.Plyr = require('plyr');
window.AOS = require('aos');
window.TouchSwipe = require('jquery-touchswipe');
    

var MonteReiApp = (function(){
    // modules
	
    window.Navigation = require('./_modules/navigation');
    window.FindApartment = require('./_modules/findApartment');
    window.FindVilla = require('./_modules/findVilla');
    window.FindVillaPlot = require('./_modules/findVillaPlot');
    window.FindPlot = require('./_modules/findPlot');
    window.CourseMap = require('./_modules/courseMap');
    window.CookiePolicy = require('./_modules/cookiePolicy');
    window.VideoPlayer = require('./_modules/videoPlayer');
    window.fancybox = require('../../node_modules/fancybox/dist/js/jquery.fancybox.js');


    

    // document readyn
    $(document).ready(function(){

    
        //cookie popup
        new CookiePolicy();

        // navigation
        var header = $('.js-header');
        if (header.length) {
            window.navigation = new Navigation($('.js-header'));
        }

        // course map select
        var courseMap = $('section.courseMap');
        if (courseMap.length){
            //createSpotViewer();
            new CourseMap(courseMap);
        }
		
		var resortMap = $('section.resortMap');
		if (resortMap.length){
			new CourseMap(resortMap);
		}	


        var $players = $('section.videoPlayer');
        if($players.length) {

            $players.each(function(i, player){
                new VideoPlayer($(player));

            });

        }


        if(is_touch_device()) {
            $('body').addClass('touch-enabled');
        }

        //////////////////////////////////////////////////

        $(".book").click(function(){
            var $this = $(this);
            if($this.data('clicked'), true) {
                console.log('clicked')
                $this.toggleClass('.contents')
            }
            else {
                console.log('not clicked')
            }
        });

        //////////////////////////////////////////////////

        // find your apartment
        var viewerCreated = false;
        function createSpotViewer(){
            if (!viewerCreated) {
                $(document.body).append('<div class="spotViewer"><div class="bg"></div><div class="close"><span class="icon icon-close"></div><div class="imgHolder"><img src=""></div></div>')
                viewerCreated = true;
            }
        }


        var findApartment = $('section.findYourApartment');
        if (findApartment.length){
            createSpotViewer();
            for (var i = 0; i < findApartment.length; i++) {
                new FindApartment($(findApartment[i]));
            }
        }

        // find your villa
        var findVilla = $('section.findYourVilla');
        if (findVilla.length){
            createSpotViewer();
            for (var i = 0; i < findVilla.length; i++) {
                new FindVilla($(findVilla[i]));
            }
        }

        // find your villa plot
        var findVillaPlot = $('section.findYourVillaPlot');
        if (findVillaPlot.length){
            createSpotViewer();
            for (var i = 0; i < findVillaPlot.length; i++) {
                new FindVillaPlot($(findVillaPlot[i]));
            }
        }

        // find your plot
        var findPlot = $('section.findYourPlot');
        if (findPlot.length){
            createSpotViewer();
            for (var i = 0; i < findPlot.length; i++) {
                new FindPlot($(findPlot[i]));
            }
        }

        // form pop ups
        var formPopups = $('a.formPopup');
        if (formPopups.length){
            // create popup container
            $(document.body).append('<div class="js-formPopup"><div class="bg"></div><div class="close"><span class="icon icon-close"></div><div class="overlayContent"><iframe src=""></div></div>')

            var popup = $('.js-formPopup');
            var bg = popup.find('.bg');
            var iframe = popup.find('iframe');
            var close = popup.find('.close');
            var overlayContent = popup.find('.overlayContent');

            formPopups.on('click',function(e){
                e.preventDefault();
                e.stopPropagation();
                var el = $(this);
                var url = el.attr('href');

                iframe.attr('src',url);
                popup.addClass('active')
                TweenMax.to(bg,0.6,{className:'+=active',ease:Strong.easeOut});
                TweenMax.to(close,0.6,{className:'+=active',ease:Strong.easeOut});
                TweenMax.to(overlayContent,0.6,{className:'+=active',ease:Strong.easeOut});
                $('body').addClass('no-scroll');
                //$('iframe').iFrameResize();
            })

            function closePopUp() {
                TweenMax.to(close,0.6,{className:'-=active',ease:Strong.easeIn});
                TweenMax.to(overlayContent,0.6,{className:'-=active',ease:Strong.easeIn});
                TweenMax.to(bg,0.6,{className:'-=active',ease:Strong.easeOut,delay:0.6,onComplete:function(){
                    popup.removeClass('active');
                    $('body').removeClass('no-scroll');
                    iframe.attr('src','');
                }});
            }

            close.on('click',function(e){
                e.preventDefault();
                e.stopPropagation();
                closePopUp();

            });
            $('iframe').iFrameResize();

            iframe.on('load', function(){
                var el = $(this).contents();

                setTimeout(function(){
                    console.log("Form Resizing");
                    $('iframe').iFrameResize().resize();
                },2000);

                el.find('form').on('submit', function(e){
                    if(!$(this).valid())
                    {
                            e.preventDefault();
                            return false;
                    }
                    
                    setTimeout(function(){
                        console.log('submitted');
                        closePopUp();
                    }, 1000)
                })
            });
        }

         // cookie pop up
         if(window.localStorage.getItem('monte-rei-accept-cookies') == "false") {

            var template = '<div class="cookie-bg"> <div class="advert"><div class="close"><span class="icon icon-close"></div><div id="textContainer"> <img id="MR-logo" src="https://www.worldclass.golf/monterei/monte-rei.png" alt="MR-logo" /> <h2 id="header"> 4% RENTAL GUARANTEE FOR FOUR YEARS ON OUR NEW CLUBHOUSE RESIDENCES </h2> <h3 id="subheader"> (ON SELECTED APARTMENTS) </h3> <a href="/en/real-estate/investment-opportunities-at-monte-rei/"> <div id="button"> <h3 id="buttonText"> Find Out More </h3> </div> </a> <label id="pop-up-label"> <input id="cookie-checkbox" type="checkbox" name="cookie-checkbox" value="accept"> Dont show me this again </label> </div> <img id="pop-up-image" src="https://monte-rei.com/media/2076/popup.jpg" alt="pop-up-image"/></div> </div>'
            
              // create popup container
             $(document.body).append(template)
            
             var adContainer = $('.cookie-bg')
             var adPopup = $('.advert');
             var adbg = adPopup.find('.bg');
             var iframe = adPopup.find('iframe');
             var close = adPopup.find('.close');
             var overlayContent = adPopup.find('.overlayContent');
             var adCheck = adPopup.find('#cookie-checkbox')
             var buttonCheck = adPopup.find('#button')
            
            adContainer.addClass('active')
            adPopup.addClass('active')
            TweenMax.to(adbg,0.6,{className:'+=active',ease:Strong.easeOut});
            TweenMax.to(close,0.6,{className:'+=active',ease:Strong.easeOut});
            TweenMax.to(overlayContent,0.6,{className:'+=active',ease:Strong.easeOut});
            $('body').addClass('no-scroll');

            adCheck.change(function(event) {
                var checkbox = event.target;
                if (checkbox.checked) {
                    window.localStorage.setItem("monte-rei-accept-cookies", 'true');
                    console.log('cookies set to true')
                } else {
                    window.localStorage.setItem("monte-rei-accept-cookies", 'false');
                    console.log('cookies set to false')
                }
            })

            buttonCheck.on('click', function(e) {
                if(window.localStorage.getItem('monte-rei-accept-cookies') == "false") {
                    window.localStorage.setItem("monte-rei-accept-cookies", 'true');
                    console.log('cookies set to true');
                }
                window.location.reload();
            })

 
             function closePopUp() {
                 TweenMax.to(close,0.6,{className:'-=active',ease:Strong.easeIn});
                 TweenMax.to(overlayContent,0.6,{className:'-=active',ease:Strong.easeIn});
                 TweenMax.to(adbg,0.6,{className:'-=active',ease:Strong.easeOut,delay:0.6,onComplete:function(){
                    adContainer.removeClass('active')
                    adPopup.removeClass('active');
                     $('body').removeClass('no-scroll');
                     iframe.attr('src','');
                 }});
             }
 
             close.on('click',function(e){
                //  e.preventDefault();
                //  e.stopPropagation();
                 window.localStorage.setItem("monte-rei-accept-cookies", 'true');
                 console.log('cookies set to true');
                 closePopUp();
                 window.location.reload();
             });
             $('iframe').iFrameResize();
 
             iframe.on('load', function(){
                //  var el = $(this).contents();
 
                 setTimeout(function(){
                     console.log("Cookie Popup Resizing");
                     $('iframe').iFrameResize().resize();
                 },2000);
 
                //  el.find('advert').on('submit', function(){
                //      setTimeout(function(){
                //          console.log('submitted');
                //          closePopUp();
                //      }, 1000)
                //  })
             });
         }

        // init videos (non-bg)
        var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;


        var videos = $('.video video');

        if (videos.length){
        
            for (var i = 0; i < videos.length; i++) {
                if (!isIE11) {
                    var player = new Plyr($(videos[i]),{
                        clickToPlay: false
                    });
                } 
            }
        }

        // slider arrow positions!!
        var sliders = $('.main-carousel');
        sliders.each(function(){
            var slider = $(this);
            var title = slider.parent().find('h2');
            var arrows = slider.find('.flickity-prev-next-button');
            $(window).on('resize',function(){
                var titleHeight = title.innerHeight();
                var titleOuterHeight = title.outerHeight(true)
                var titleSpace = titleOuterHeight - titleHeight;
                var arrowSpace = titleHeight/2 + (titleSpace);
                arrows.css('top',arrowSpace*-1);
            })

            if(typeof(Event) === 'function') {
                var event = new Event('resize');
            }else{
                var event = document.createEvent('Event');
                event.initEvent('resize', true, true);
            }
            window.dispatchEvent(event);
        })

        // page hero animation
        var pageHero = $('.pageHero');
        if (pageHero.length){
            pageHero.each(function(){
                var el = $(this);
                var title = el.find('h1');
                var desc = el.find('p');
                TweenMax.fromTo(title,1,{alpha:0},{alpha:1,delay:0.5});
                TweenMax.fromTo(desc,1,{alpha:0},{alpha:1,delay:0.5});
            })
        }

        // animate on scroll
        window.offsetHeight = (window.h/2 - window.h/4)*-1;
        $(document.body).find('[data-aos]').each(function(){
            $(this).attr('data-aos-offset',offsetHeight);
        })
        AOS.init();
		
		var a = $("a[href$='/vistas-restaurant/']"); 
		if (a.length > 0){ 
			a.each(function(){
			 $(this).attr('href', 'https://www.vistasrestaurante.com');
			}); 
        } 


    });

    // page load
    $(window).on('load', function() {
        $(window).trigger('resize');
        $(window).trigger('scroll');
        if(typeof(Event) === 'function') {
            var event = new Event('resize');
        }else{
            var event = document.createEvent('Event');
            event.initEvent('resize', true, true);
        }
        window.dispatchEvent(event);

	var waypoint = document.querySelector('.nav-waypoint'),
		mobChecker = true,
		scrollTrigger = document.querySelector('.scrollTrigger');
		if (mobChecker && $(window).width() < 1149) {
				mobChecker = false;
				document.querySelector('header').classList.remove('scrolled');			
			}	
		scrollTrigger.addEventListener('click',function(e){
			if (waypoint){
				window.scrollTo(0, waypoint.getBoundingClientRect().bottom);
			} else {
				document.querySelector('header').classList.remove('scrolled');
			}	
		});
	
		$(window).on('scroll', function(){
			if ($(window).width() > 1149 && waypoint != null){
				if (waypoint.getBoundingClientRect().top > 100){
					document.querySelector('header').classList.add('scrolled');
				}
				if (waypoint.getBoundingClientRect().top < 100){
					document.querySelector('header').classList.remove('scrolled');
				}
			}
		});
		
    });

    // page resize
    $(window).on('resize', function() {
        window.w = window.innerWidth;
        window.h = window.innerHeight;
        window.offsetHeight = (window.h/2 - window.h/4)*-1;
        $(document.body).find('[data-aos]').each(function(){
            $(this).attr('data-aos-offset',offsetHeight);
        })
        AOS.refresh();
    });

/*     //page scroll
    $(window).on('scroll', function(){
        var $header = $('header');
        if($(document).scrollTop() >= window.innerHeight*.7 && !$header.hasClass('scrolled')) {
            $header.addClass('scrolled');
        } else if ($(document).scrollTop() <= window.innerHeight*.7 && $header.hasClass('scrolled')) {
            $header.removeClass('scrolled');
        }

    }); */


    function is_touch_device() {
        var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
        var mq = function(query) {
          return window.matchMedia(query).matches;
        }
      
        if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
          return true;
        }
      
        // include the 'heartz' as a way to have a non matching MQ to help terminate the join
        // https://git.io/vznFH
        var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
        return mq(query);
      } 


        //Thumbs
        var setupThumGrid = function(){

             //Setup image and button
            $(".thumbnail_grid").each(function(){
                    var titles = $(this).find(".imagewithbutton__title");
                    titles.height("auto");
                    
                    var biggest = 0;
                    titles.each(function(){
                        var height = $(this).height();
                        if(height > biggest)
                        {
                            biggest = height;
                        }
                    });

                    titles.height(biggest);



            });
        };

      
        setupThumGrid();    


        $(window).resize(function(){
            setupThumGrid();    
        });

        ////// Hide Language Button on Packages Pages
    $(function(){
        if (window.location.pathname == "/en/accommodation/packages/" || window.location.pathname == "/en/accommodation/packages" || window.location.pathname == "/en/accommodation/packages/" || window.location.pathname == "/en/accommodation/packages/get-your-game-ready" || window.location.pathname == "/en/accommodation/packages/get-your-game-ready/" || window.location.pathname == "/en/accommodation/packages/algarve-golf-experience" || window.location.pathname == "/en/accommodation/packages/algarve-golf-experience/" || window.location.pathname == "/en/accommodation/packages/jacks-essential" || window.location.pathname == "/en/accommodation/packages/jacks-essential/") {
              $('.lang.new').hide();
        } else {
              $('.lang.new').show();
        }
   })

   //// UNDER OFFER LOGOS on RESALE PAGE /////
    if (window.location.pathname == '/en/real-estate/resale/') {
        document.addEventListener("DOMContentLoaded", function(event) {
            var underOfferTemplate = document.createElement('h4');
            underOfferTemplate.id = 'UnderOffer';
            underOfferTemplate.innerHTML = 'SOLD';

            var underOfferTemplate2 = document.createElement('h4');
            underOfferTemplate2.id = 'UnderOffer';
            underOfferTemplate2.innerHTML = 'UNDER OFFER';

            document.getElementById('Individual Villa 29').append(underOfferTemplate);

            document.getElementById('Linked Villa 35').append(underOfferTemplate2);
        })
    }



})();


